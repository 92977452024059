import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminRoutesProtection from './AdminRoutesProtection';
import { Navigate } from 'react-router-dom';
const Profile = Loadable(lazy(() => import('views/pages/all/Profile')));
const Payments = Loadable(lazy(() => import('views/pages/all/Payments')));
const AccountSettings = Loadable(lazy(() => import('views/pages/all/AccountSettings')));
const NewStokvel = Loadable(lazy(() => import('ui-component/new-stokvel')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// pages routes
const Clients = Loadable(lazy(() => import('views/pages/all/Clients')));
const Wallets = Loadable(lazy(() => import('views/pages/all/Wallets')));
const Loan = Loadable(lazy(() => import('views/pages/all/Loan')));
const Transactions = Loadable(lazy(() => import('views/pages/all/Transactions')));
const Stokvels = Loadable(lazy(() => import('views/pages/all/Stokvels')));
const Types = Loadable(lazy(() => import('views/pages/all/Types')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AdminRoutesProtection>
            <MainLayout />
        </AdminRoutesProtection>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="dashboard/default" replace />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'clients',
            element: <Clients />
        },
        {
            path: 'wallets',
            element: <Wallets />
        },
        {
            path: 'loans',
            element: <Loan />
        },
        {
            path: 'transactions',
            element: <Transactions />
        },
        {
            path: 'payments',
            element: <Payments />
        },
        {
            path: 'stokvels',
            element: <Stokvels />
        },
        {
            path: 'stokvel_types',
            element: <Types />
        },
        {
            path: 'new_stokvel',
            element: <NewStokvel />
        },
        {
            path: 'edit_stokvel/:id',
            element: <NewStokvel />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'settings',
            element: <AccountSettings />
        }
    ]
};

export default MainRoutes;
