import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ClientRoutes from './ClientRoutes';
import NotFound from 'views/pages/all/NotFound';
import Welcome from 'views/pages/all/Welcome';
import NotAuthorized from 'views/pages/all/NotAuthorized';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        ClientRoutes,
        AuthenticationRoutes,
        {
            path: '*',
            element: <NotFound />
        },
        {
            path: 'unauthorized',
            element: <NotAuthorized />
        },
        {
            path: '/welcome',
            element: <Welcome />
        }
    ]);
}
