import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ContextProvider, useStateContext } from 'context/ContextProvider';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useStateContext();

    return (
        <StyledEngineProvider injectFirst>
            <ContextProvider>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </ContextProvider>
        </StyledEngineProvider>
    );
};

export default App;
