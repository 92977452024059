import { useTheme } from '@mui/material/styles';
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import AuthFooter from 'ui-component/cards/AuthFooter';

// assets
import logo from 'assets/images/logo.jpeg';
import { useStateContext } from 'context/ContextProvider';

const NotAuthorized = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { user } = useStateContext();

    return (
        <div>
            <Paper>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Typography
                                    marginBottom={5}
                                    color={theme.palette.secondary.main}
                                    gutterBottom
                                    variant={matchDownSM ? 'h4' : 'h3'}
                                >
                                    Hi..., {`${user.name} ${user.surname}`} you not allowed to access this page !!!
                                </Typography>
                                <Box display="flex" gap={2} flexWrap="wrap" alignItems="center" justifyContent="center">
                                    <Box mb={3}>
                                        <img src={logo} alt="logo" />
                                    </Box>
                                    <Box>
                                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                            Stokvel eSwatini
                                        </Typography>
                                        <Typography
                                            color={theme.palette.secondary.main}
                                            gutterBottom
                                            variant={matchDownSM ? 'h4' : 'h3'}
                                            style={{ fontStyle: 'italic' }}
                                        >
                                            &quot; Were here for you Eswatini, Grow with us&quot;
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default NotAuthorized;
