// assets
import {
    EventNoteOutlined,
    JoinInner,
    LocalAtmOutlined,
    MeetingRoom,
    MoneyOutlined,
    PaidOutlined,
    PeopleAltOutlined
} from '@mui/icons-material';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pages',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'stokvels',
            title: 'Stokvels',
            type: 'item',
            url: '/client_stokvels',
            icon: EventNoteOutlined,
            breadcrumbs: true
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/client_transactions',
            icon: PaidOutlined,
            breadcrumbs: true
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/client_payments',
            icon: MoneyOutlined,
            breadcrumbs: true
        },

        // {
        //     id: 'clients',
        //     title: 'Clients',
        //     type: 'item',
        //     url: '/stokvel_clients',
        //     icon: PeopleAltOutlined,
        //     breadcrumbs: true
        // },
        {
            id: 'loans',
            title: 'Loans',
            type: 'item',
            url: '/stokvel_loans',
            icon: LocalAtmOutlined,
            breadcrumbs: true
        },
        {
            id: 'request',
            title: 'Stokvel Request',
            type: 'item',
            url: '/stokvel_request',
            icon: JoinInner,
            breadcrumbs: true
        },
        {
            id: 'meetings',
            title: 'Meetings',
            type: 'item',
            url: '/meetings',
            icon: MeetingRoom,
            breadcrumbs: true
        }
    ]
};

export default pages;
