import { useStateContext } from 'context/ContextProvider';
import { Navigate } from 'react-router-dom';

const ClientRoutesProtection = ({ children }) => {
    const { token, user } = useStateContext();

    if (!token) {
        <Navigate to="/pages/login/login3" replace />;
    }

    const uSet = new Set(['owner', 'chairperson', 'treasurer']);

    if (Object.keys(user).length !== 0 && uSet.has(user.type) !== true) {
        return <Navigate to="/pages/login/login3" replace />;
    }

    return uSet.has(user.type) && children;
};

export default ClientRoutesProtection;
