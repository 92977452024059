import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ClientLayout from 'layout/ClientLayout';
import ClientRoutesProtection from './ClientRoutesProtection';
import { Navigate } from 'react-router-dom';
const Profile = Loadable(lazy(() => import('views/pages/all/Profile')));
const AccountSettings = Loadable(lazy(() => import('views/pages/all/AccountSettings')));

// pages routes
const StokvelClients = Loadable(lazy(() => import('views/pages/all/StokvelClients')));
const Pay = Loadable(lazy(() => import('views/pages/all/Pay')));
const Payments = Loadable(lazy(() => import('views/pages/all/Payments')));
const StokvelWallets = Loadable(lazy(() => import('views/pages/all/StokvelWallets')));
const StokvelLoans = Loadable(lazy(() => import('views/pages/all/StokvelLoans')));
const ClientStokvels = Loadable(lazy(() => import('views/pages/all/ClientStokvels')));
const ClientTransactions = Loadable(lazy(() => import('views/pages/all/ClientTransactions')));
const ClientDashboard = Loadable(lazy(() => import('views/dashboard/Client')));
const StokvelRequests = Loadable(lazy(() => import('views/pages/all/StokvelRequests')));
const Meetings = Loadable(lazy(() => import('views/pages/all/Meetings')));
const NewMeetings = Loadable(lazy(() => import('ui-component/new-meetings')));

// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
    path: '/',
    element: (
        <ClientRoutesProtection>
            <ClientLayout />
        </ClientRoutesProtection>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/dashbord/client_dashbord" replace />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'client_dashboard',
                    element: <ClientDashboard />
                }
            ]
        },
        {
            path: 'stokvel_clients',
            element: <StokvelClients />
        },
        {
            path: 'client_payments',
            element: <Payments />
        },
        {
            path: 'meetings',
            element: <Meetings />
        },
        {
            path: 'pay/:id',
            element: <Pay />
        },
        {
            path: 'stokvel_request',
            element: <StokvelRequests />
        },
        {
            path: 'stokvel_loans',
            element: <StokvelLoans />
        },
        {
            path: 'stokvel_wallets',
            element: <StokvelWallets />
        },
        {
            path: 'client_transactions',
            element: <ClientTransactions />
        },
        {
            path: 'client_stokvels',
            element: <ClientStokvels />
        },
        {
            path: 'client_profile',
            element: <Profile />
        },
        {
            path: 'client_settings',
            element: <AccountSettings />
        },
        {
            path: 'new_meeting',
            element: <NewMeetings />
        },
        {
            path: 'meeting/:id',
            element: <NewMeetings />
        }
    ]
};

export default ClientRoutes;
