// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

import AuthFooter from 'ui-component/cards/AuthFooter';

// assets
import logo from 'assets/images/logo.jpeg';
import { useStateContext } from 'context/ContextProvider';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthWrapper1 from '../authentication/AuthWrapper1';
import swal from 'sweetalert';

const Welcome = () => {
    const theme = useTheme();
    const { client, user, setUser, setToken, setNotification } = useStateContext();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [isSubmitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleLogout = (e) => {
        setSubmitting(true);
        e.preventDefault();
        client.post('/logout').then((res) => {
            if (res.status === 200) {
                localStorage.removeItem('ACCESS_TOKEN');
                swal('Success', res.data.message, 'success');
                setSubmitting(false);
                setToken(null);
                setUser({});
                navigate('/pages/login/login3');
            }
        });
    };

    return (
        <div>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item xs={6} style={{ textAlign: 'center' }}>
                                <Typography mb={5} color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                    Hi..., {`${user.name} ${user.surname}`}
                                </Typography>
                                <Box display="flex" gap={2} flexWrap="wrap" alignItems="center" justifyContent="center">
                                    <Box mb={2}>
                                        <img src={logo} alt="logo" />
                                    </Box>
                                    <Box>
                                        <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h2' : 'h1'}>
                                            Welcome to stokvel Eswatini
                                        </Typography>

                                        <Typography
                                            color={theme.palette.secondary.main}
                                            gutterBottom
                                            variant={matchDownSM ? 'h4' : 'h3'}
                                            style={{ fontStyle: 'italic' }}
                                        >
                                            &quot;We're here for you Eswatini, Grow with us&quot;
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                        <AuthFooter />
                    </Grid>
                </Grid>
            </AuthWrapper1>
        </div>
    );
};

export default Welcome;
