//   import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.jpeg';
import Avatar from './extended/Avatar';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    return (
        <Box display="flex" gap={2} alignItems="center">
            <Avatar src={logo} alt="stokvel" width="100" />
            <Typography variant="h2" color={theme.palette.secondary.main}>
                Commvest
            </Typography>
        </Box>
    );
};

export default Logo;
