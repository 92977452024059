import { Link } from 'react-router-dom';
// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import { useStateContext } from 'context/ContextProvider';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const { user } = useStateContext();

    const handleClick = () => {
        const admin = new Set(['super_admin', 'admin']);
        let route = '';

        if (admin.has(user?.type)) {
            route = '/dashboard/default';
        } else {
            route = '/dashboard/default';
        }
        return route;
    };
    return (
        <ButtonBase disableRipple component={Link} to={handleClick}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
