// assets
import {
    AccountBalanceWalletOutlined,
    EventAvailableOutlined,
    EventNoteOutlined,
    LocalAtmOutlined,
    MoneyOutlined,
    PaidOutlined,
    PeopleAltOutlined
} from '@mui/icons-material';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pages',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'stokvels',
            title: 'Stokvels',
            type: 'item',
            url: '/stokvels',
            icon: EventNoteOutlined,
            breadcrumbs: true
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/transactions',
            icon: PaidOutlined,
            breadcrumbs: true
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/payments',
            icon: MoneyOutlined,
            breadcrumbs: true
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/clients',
            icon: PeopleAltOutlined,
            breadcrumbs: true
        },
        {
            id: 'loans',
            title: 'Loans',
            type: 'item',
            url: '/loans',
            icon: LocalAtmOutlined,
            breadcrumbs: true
        },
        {
            id: 'wallets',
            title: 'Wallets',
            type: 'item',
            url: '/wallets',
            icon: AccountBalanceWalletOutlined,
            breadcrumbs: true
        },
        {
            id: 'types',
            title: 'Stokvel Types',
            type: 'item',
            url: '/stokvel_types',
            icon: EventAvailableOutlined,
            breadcrumbs: true
        }
    ]
};

export default pages;
