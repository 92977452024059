import { useStateContext } from 'context/ContextProvider';
import { Navigate } from 'react-router-dom';
// import Swal from 'sweetalert2';

const AdminRoutesProtection = ({ children }) => {
    const { user, token } = useStateContext();
    const uSet = new Set(['super_admin', 'admin']);

    if (!token) {
        <Navigate to="/pages/login/login3" replace />;
    }

    if (Object.keys(user).length !== 0 && uSet.has(user.type) !== true) {
        // Swal.fire('Warning', 'Unauthorized', 'warning');
        return <Navigate to="/unauthorized" />;
    }
    return uSet.has(user.type) && children;
};

export default AdminRoutesProtection;
