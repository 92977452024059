import { HomeOutlined } from '@mui/icons-material';

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/client_dashboard',
            icon: HomeOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
